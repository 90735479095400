<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <div class="card card-body">
              Equipes
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

export default {
  name: "Equipes",
  page: {
    title: "Equipes",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "Equipes",
    };
  },
  computed: {

  },
  mounted() {

  },
  watch: {

  },
  created() {

  },
  filters: {

  },
  methods: {

  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>